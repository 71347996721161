import React from "react";

import styles from "./cards.module.scss";
import Card from "../Card/Card";

import cardBg1 from "../../assets/images/card-bg-1@2x.png";
import cardBg2 from "../../assets/images/card-bg-2@2x.png";
import cardBg3 from "../../assets/images/card-bg-3@2x.png";
import cardBg4 from "../../assets/images/card-bg-4@2x.png";

import cardBgMob1 from "../../assets/images/card-bg-mobile-1@2x.png";
import cardBgMob2 from "../../assets/images/card-bg-mobile-2@2x.png";
import cardBgMob3 from "../../assets/images/card-bg-mobile-3@2x.png";
import cardBgMob4 from "../../assets/images/card-bg-mobile-4@2x.png";

const cards = [
  {
    title: "POST ON YOUR \n SOCIAL ACCOUNT",
    text: `Follow the instructions. 
    Download the photo or video, 
    copy the text and post on 
    your social wall or stories. `,
    image: cardBg1,
    imageMob: cardBgMob1,
  },
  {
    title: "RECORD AN \n ORIGINAL VIDEO",
    text: `Create a unique video,
    based on the script,
    provided by advertiser.
    Post it on your social
    wall and get the
    reward.`,
    image: cardBg2,
    imageMob: cardBgMob2,
  },
  {
    title: "POST SOCIAL CAUSES",
    text: `Create awareness for NGOs that are fighting for humanitarian causes, global warming, ocean protection. Post their causes on your wall and give a voice to those in need.`,
    image: cardBg3,
    imageMob: cardBgMob3,
    center: true,
  },
  {
    title: "WEEKLY RAFFLES",
    text: `Every time you post a social
    cause your earn some points.
    Use the points to join our
    weekly raffles and win a lot
    of prizes sponsored by our
    advertisers.`,
    image: cardBg4,
    imageMob: cardBgMob4,
  },
];

function Cards() {
  return (
    <div className={styles.cards}>
      {cards.map((el, index) => (
        <Card
          key={index}
          title={el.title}
          text={el.text}
          image={el.image}
          imageMob={el.imageMob}
          center={el.center}
        />
      ))}
    </div>
  );
}

export default Cards;
