import React from "react";

import styles from "./steps.module.scss";
import Step from "../Step/Step";

import stepBg1 from "../../assets/images/step-bg-1@2x.png";
import stepBg2 from "../../assets/images/step-bg-2@2x.png";
import stepBg3 from "../../assets/images/step-bg-3@2x.png";

const steps = [
  {
    number: "STEP 1",
    title: "CHOOSE WHAT YOU \n WANT TO POST",
    description: `You choose what you want 
    to post. Products, brands, 
    services, influencers, news 
    articles, music videos and
    social causes.`,
    image: stepBg1,
  },
  {
    number: "STEP 2",
    title: "POST ON YOUR \n WALL OR STORIES",
    description: `You can use any of the 
    social media networks you
    want. We support Facebook,
    TikTok, Instagram & Twitter.`,
    image: stepBg2,
  },
  {
    number: "STEP 3",
    title: "VERIFY AND \n GET PAID",
    description: `Once you made the post
    you get paid instantly. You
    can withdraw the funds to 
    your bank account. `,
    image: stepBg3,
  },
];

const Steps = ({ titleRef }) => {
  return (
    <div className={styles.steps} ref={titleRef}>
      {steps.map((el, index) => (
        <Step
          key={index}
          number={el.number}
          title={el.title}
          description={el.description}
          image={el.image}
        />
      ))}
    </div>
  );
};

export default Steps;
