import React from "react";
import cx from "classnames";

import FooterMenu from "../FooterMenu/FooterMenu";
import SocialLinks from "../SocialLinks/SocialLinks";
import styles from "./footer.module.scss";

import iconCopy from "../../assets/images/icons/icon-copyright.svg";

function Footer() {
  return (
    <div className={styles.footerWrapper}>
      <footer className={cx(styles.footer, "wrapper", "wrapper--v2")}>
        <div className={cx(styles.footerLeft, styles.footerHide)}>
          {/* <Link to="/" className={styles.footerLogo}>
            <Logo />
          </Link> */}
          <SocialLinks />
        </div>
        <FooterMenu />
        <div className={cx(styles.footerCopy)}>
          <img src={iconCopy} alt="Copyright" />
          Copyright Verbyo 2019
        </div>
      </footer>
    </div>
  );
}

export default Footer;
