import React from "react";

import LinkOutside from "../Button/LinkOutside";
import globals from "../../globals";
import messengerSvg from "../../assets/images/icons/icon-facebook-messenger.svg";

const FaqContent = () => {
  return (
    <>
      <div className="slidePopUp__pretitle">ANSWERS TO</div>
      <h3 className="slidePopUp__header">FREQUENTLY ASKED QUESTIONS</h3>
      <h4 className="slidePopUp__title">HOW DO I GET PAID?</h4>
      <p className="slidePopUp__text">
        Every time you post something you get paid. Once you have reached a
        minimum of $ 50 in your Verbyo account, you can ask for a payment. You
        can get paid using following methods: Bank Account, PayPal or Wise.
      </p>
      <h4 className="slidePopUp__title">HOW MUCH MONEY CAN I EARN?</h4>
      <p className="slidePopUp__text">You can earn up to $500 per month.</p>
      <h4 className="slidePopUp__title">HOW MANY FOLLOWERS DO I NEED?</h4>
      <p className="slidePopUp__text">
        You do not have to be an influencer to sign up with us. Verbyo is for
        everybody. As long as you have <span>minimum 300 friends</span> on your
        social account, you can sign up with us.
      </p>
      <h4 className="slidePopUp__title">WHAT CAN I POST?</h4>
      <p className="slidePopUp__text">
        You can post music videos, news articles, products, social causes,
        influencers, gamers and much more.
      </p>
      <h4 className="slidePopUp__title">CAN I USE MY PAGE TO POST?</h4>
      <p className="slidePopUp__text">
        No. You can only post on your social account. You can post on your wall
        or stories.
      </p>
      <h4 className="slidePopUp__title">WHAT SOCIAL NETWORKS CAN I USE?</h4>
      <p className="slidePopUp__text">
        You can use Facebook, Instagram, TikTok and Twitter. We are working on
        implementing other social networks and platforms.
      </p>
      <div className="slidePopUp__btn">
        <LinkOutside
          text={
            <>
              <img src={messengerSvg} alt="" /> Live Support
            </>
          }
          to={globals.FACEBOOK_LINK}
          color="white-shadow"
          size="lg"
          fontWeight="bold"
          stylesAttr={{
            textTransform: "uppercase",
            marginTop: "40px",
          }}
        />
      </div>
    </>
  );
};

export default FaqContent;
