import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./tipPopup.scss";
import ReactPlayer from "react-player";

import styles from "./campaignVideo.module.scss";

function CampaignVideo({ href }) {
  const [videoSize, setVideoSize] = useState({
    width: "0px",
    height: "0px",
  });

  const size = useWindowSize();

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });

    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  }

  useEffect(() => {
    if (window.innerWidth >= 952) {
      setVideoSize({
        width: "952px",
        height: "563px",
      });
    } else if (window.innerWidth >= 670) {
      setVideoSize({
        width: "640px",
        height: "360px",
      });
    } else if (window.innerWidth >= 490) {
      setVideoSize({
        width: "480px",
        height: "270px",
      });
    } else {
      setVideoSize({
        width: "320px",
        height: "180px",
      });
    }
  }, [size]);

  return (
    <div className={cx(styles.tip)}>
      <div className={styles.videoWrapper}>
        {href !== "" && (
          <ReactPlayer
            url={href}
            width={videoSize.width}
            height={videoSize.height}
            config={{
              youtube: {
                playerVars: { showinfo: 1, modestbranding: 1 },
              },
            }}
          />
        )}
      </div>
    </div>
  );
}

CampaignVideo.defaultProps = {
  icon: "help",
  content: null,
  headerVariant: "v1",
};

CampaignVideo.propTypes = {
  content: PropTypes.element,
  close: PropTypes.func,
};

export default CampaignVideo;
