import React from "react";

import styles from "./arrowDown.module.scss";
import iconArrow from "../../assets/images/icons/icon-arrow-down.svg";

function ArrowDown({ handleBackClick }) {
  return (
    <div className={styles.arrowDown}>
      <img src={iconArrow} alt="Arrow Down" onClick={() => handleBackClick()} />
    </div>
  );
}

export default ArrowDown;
