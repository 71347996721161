import React from "react";

import IconFacebook from "../../assets/images/icons/icon-facebook-gray.svg";
import IconInstagram from "../../assets/images/icons/icon-instagram-gray.svg";
import IconTwitter from "../../assets/images/icons/icon-twitter-gray.svg";
import IconMessenger from "../../assets/images/icons/icon-messenger-gray.svg";
import styles from "./socialLinks.module.scss";
import globals from "../../globals";

function SocialLinks() {
  return (
    <ul className={styles.social}>
      <li>
        <a href={globals.INSTAGRAM_LINK} target="_blank" rel="noreferrer">
          <img src={IconInstagram} alt="Instagram Link" />
        </a>
      </li>
      <li>
        <a href={globals.FACEBOOK_LINK} target="_blank" rel="noreferrer">
          <img src={IconFacebook} alt="Facebook Link" />
        </a>
      </li>
      <li>
        <a href={globals.TWITTER_LINK} target="_blank" rel="noreferrer">
          <img src={IconTwitter} alt="Twitter Link" />
        </a>
      </li>
      <li>
        <a href={globals.FACEBOOK_LINK} target="_blank" rel="noreferrer">
          <img src={IconMessenger} alt="Facebook Messenger Link" />
        </a>
      </li>
    </ul>
  );
}

export default SocialLinks;
