import React from "react";
import PropTypes from "prop-types";

import styles from "./footerMenu.module.scss";

function FooterMenu() {
  return (
    <>
      <nav>
        <ul className={styles.footerMenu}>
          <li>
            <a href="/privacy" className={styles.link}>
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="/terms" className={styles.link}>
              Terms & Conditions
            </a>
          </li>
          <li>
            <a href="/contact" className={styles.link}>
              Contact Us
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
}

FooterMenu.defaultProps = {
  mobileMode: false,
};

FooterMenu.propTypes = {
  mobileMode: PropTypes.bool,
};

export default FooterMenu;
