import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./button.module.scss";

function Button(props) {
  const {
    onClick,
    text,
    outline,
    size,
    color,
    disabled,
    fullWidth,
    isSubmit,
    shadow,
    fontWeight,
    stylesAttr,
    className,
  } = props;

  return (
    <button
      style={{ ...stylesAttr }}
      className={cx(
        styles.button,
        outline && styles.outline,
        color && styles[color],
        size && styles[size],
        fullWidth && styles.fullWidth,
        shadow && styles.shadow,
        styles[fontWeight],
        className
      )}
      onClick={onClick}
      disabled={disabled}
      type={isSubmit ? "submit" : "button"}
    >
      {text}
    </button>
  );
}

Button.defaultProps = {
  onClick: null,
  outline: false,
  color: null,
  size: "m",
  disabled: false,
  fullWidth: false,
  isSubmit: false,
  shadow: false,
  fontWeight: "bold",
  stylesAttr: {},
};

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  outline: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "white-bordered",
    "white-shadow",
    "blue-shadow",
  ]),
  size: PropTypes.oneOf(["md", "h"]),
  fontWeight: PropTypes.oneOf(["bold", "regular"]),
  stylesAttr: PropTypes.object,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isSubmit: PropTypes.bool,
  shadow: PropTypes.bool,
};

export default Button;
