import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./mainBussines.module.scss";

function MainBussines({
  pretitle,
  title,
  description,
  image,
  buttons,
  logo,
  href,
}) {
  const mobileWidth = 480;
  const isMobile = () => window.innerWidth <= mobileWidth;

  const [mobile, setMobile] = useState(isMobile() ? true : false);

  useEffect(() => {
    function handleResize() {
      setMobile(isMobile() ? true : false);
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return !mobile ? (
    <div
      className={cx(styles.mainSlide)}
      style={{ backgroundImage: `url(${image})` }}
    >
      {pretitle && <div className={styles.pretitle}>{pretitle}</div>}
      {title && <div className={styles.title}>{title}</div>}
      {description && <div className={styles.description}>{description}</div>}
      {buttons && <div className={styles.buttons}>{buttons}</div>}
      {logo && (
        <div className={styles.logo}>
          <img src={logo} alt="" />
        </div>
      )}
    </div>
  ) : (
    <a
      href={href}
      className={cx(styles.mainSlide)}
      style={{ backgroundImage: `url(${image})` }}
      target="_blank"
      rel="noreferrer"
    >
      {pretitle && <div className={styles.pretitle}>{pretitle}</div>}
      {title && <div className={styles.title}>{title}</div>}
      {description && <div className={styles.description}>{description}</div>}
      {/* {buttons && <div className={styles.buttons}>{buttons}</div>} */}
      {logo && (
        <div className={styles.logo}>
          <img src={logo} alt="" />
        </div>
      )}
    </a>
  );
}

MainBussines.defaultProps = {
  pretitle: "",
  title: "",
  description: "",
};

MainBussines.propTypes = {
  pretitle: PropTypes.string,
  title: PropTypes.object,
  description: PropTypes.object,
};

export default MainBussines;
