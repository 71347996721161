import React from "react";
import PropTypes from "prop-types";

function ImageRetina(props) {
  const { img1x, img2x, alt, width } = props;
  const attrs = {
    src: img1x,
    srcSet: `${img1x} 1x,${img2x} 2x`,
    alt: alt,
  };

  if (width) {
    attrs.width = width;
  }
  return <img {...attrs} alt={attrs.alt} />;
}

ImageRetina.defaultProps = {
  alt: "",
  width: null,
};

ImageRetina.propTypes = {
  img1x: PropTypes.string.isRequired,
  img2x: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.string,
};

export default ImageRetina;
