import React, { useState, useRef } from "react";
import Footer from "../components/Footer/Footer";
import Main from "../components/Main/Main";
import MainApp from "../components/MainApp/MainApp";
import MainBussines from "../components/MainBussines/MainBussines";
import ArrowDown from "../components/ArrowDown/ArrowDown";
import LogosRow from "../components/LogosRow/LogosRow";
import Steps from "../components/Steps/Steps";
import Cards from "../components/Cards/Cards";
import Faqs from "../components/Faqs/Faqs";
import Button from "../components/Button/Button";
import LinkOutside from "../components/Button/LinkOutside";
import TipPopUp from "../components/PopUp/TipPopUp";
import CampaignVideo from "../components/CampaignVideo/CampaignVideo";

import logo from "../assets/images/icons/logoMain.svg";
import mainAppImage from "../assets/images/main-app@2x.jpg";
import mainBusinessImage from "../assets/images/main-business@2x.jpg";
import logoImg from "../assets/images/icons/logo.svg";

const Home = () => {
  const [modalShown, setModalShown] = useState(false);
  const [video, setVideo] = useState("");
  const show = (video) => {
    setVideo(video);
    setModalShown(true);
  };

  const close = () => {
    setVideo("");
    setModalShown(false);
  };

  const titleRef = useRef();

  function handleBackClick() {
    titleRef.current.scrollIntoView({ behavior: "smooth" });
  }

  function getApp() {
    console.log(navigator.userAgent, "navigator");
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.open("https://play.google.com/store/apps/details?id=com.verbyo");
    }
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      window.open("itms-apps://itunes.apple.com/app/verbyo/id1556056038");
    }
    if (navigator.userAgent.toLowerCase().indexOf("macintosh") > -1) {
      window.open("itms-apps://itunes.apple.com/app/verbyo/id1556056038");
    }
  }

  return (
    <div className="wrapper wrapper--v2">
      <Main>
        <MainApp
          title={
            <>
              <img src={logo} alt="logo" />
              <h3>
                Verbyo <span>App</span>
              </h3>
            </>
          }
          titleImage={true}
          description={
            <>
              <p>
                The first app that rewards people every time they post on their
                social media walls or stories.
              </p>
              <p>
                Users can post brands, product, music videos, news articles,
                content creators, music artists or create a unique and original
                video based on a script provided by advertiser.
              </p>
            </>
          }
          image={mainAppImage}
          buttons={
            <>
              <Button
                // to="/"
                text="Get the app"
                color="blue-shadow"
                size="md"
                stylesAttr={{
                  textTransform: "uppercase",
                }}
                onClick={() => getApp()}
              />
              <Button
                text="Play video"
                color="white-shadow"
                size="md"
                stylesAttr={{
                  textTransform: "uppercase",
                }}
                onClick={() =>
                  show(
                    "https://www.youtube.com/watch?v=kE-M9kWmnpQ&list=PLG5tqeiSnhhG3pyxammWsnDk9GUCYf21q"
                  )
                }
              />
            </>
          }
        />

        <ArrowDown handleBackClick={handleBackClick} />
        <MainBussines
          pretitle="FOR BUSINESS"
          title={<h3>ADVERTISE WITH US</h3>}
          description={
            <>
              <p>
                If you are a{" "}
                <span>content creator, music artist, NGO or a brand</span> that
                wants to promote his products or services, our{" "}
                <span>word-of-mouth marketing solutions</span> will help you to
                raise awareness and increase your sales.
              </p>
            </>
          }
          image={mainBusinessImage}
          buttons={
            <>
              <LinkOutside
                to="https://business.verbyo.com/"
                text="SIGN UP"
                color="white-shadow"
                size="h"
                stylesAttr={{
                  textTransform: "uppercase",
                }}
              />
              {/* <Button
                text="Play video"
                color="white-shadow"
                size="md"
                stylesAttr={{
                  textTransform: "uppercase",
                }}
                onClick={() =>
                  show(
                    "https://www.youtube.com/watch?v=kE-M9kWmnpQ&list=PLG5tqeiSnhhG3pyxammWsnDk9GUCYf21q"
                  )
                }
              /> */}
            </>
          }
          logo={logoImg}
          href="https://business.verbyo.com/"
        />
      </Main>
      <LogosRow />
      <Steps titleRef={titleRef} />
      <Faqs mobile={true} />
      <Cards />
      <Faqs mobile={false} />
      <Footer />
      <TipPopUp onClose={close} open={modalShown} closeOnDocumentClick={true}>
        <CampaignVideo href={video} />
      </TipPopUp>
    </div>
  );
};

export default Home;
