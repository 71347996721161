import React from "react";
import styles from "./step.module.scss";

function Step({ number, title, description, image }) {
  return (
    <div className={styles.step} style={{ backgroundImage: `url(${image})` }}>
      <div className={styles.number}>{number}</div>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.description}>{description}</div>
    </div>
  );
}

export default Step;
