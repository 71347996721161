import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./slidePopUp.scss";
import cx from "classnames";

import iconClose from "../../assets/images/icons/icon-slide-close.svg";

function SlidePopUp({ open, onClose, children, type }) {
  const desktopWidth = 1152;
  const isDesktop = () => window.innerWidth >= desktopWidth;
  // const width = isDesktop() ? "900px" : "100%";
  const [width, setWidth] = useState(isDesktop() ? "900px" : "100%");

  useEffect(() => {
    function handleResize() {
      setWidth(isDesktop() ? "900px" : "100%");
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <SlidingPane
      className={cx(
        "slidePopUp",
        type === "left"
          ? "slidePopUp--v1"
          : type === "right"
          ? "slidePopUp--v2"
          : "slidePopUp--v3"
      )}
      overlayClassName="slidePopUp__overlay"
      isOpen={open}
      width={width}
      onRequestClose={() => onClose()}
      from={type}
      hideHeader
    >
      <div className="slidePopUp__close" onClick={() => onClose()}>
        <img src={iconClose} alt="" />
      </div>
      {children}
    </SlidingPane>
  );
}
SlidePopUp.defaultProps = {
  closeOnDocumentClick: false,
};

SlidePopUp.propTypes = {
  closeOnDocumentClick: PropTypes.bool,
};
export default SlidePopUp;
