import React, { useState } from "react";
import cx from "classnames";

import styles from "./faqs.module.scss";
import SlidePopUp from "../PopUp/SlidePopUp";
import FaqContent from "../FaqContent/FaqContent";

function Faqs({ mobile }) {
  const [modalShown, setModalShown] = useState(false);
  const show = () => {
    setModalShown(true);
  };

  const close = () => {
    setModalShown(false);
  };
  return (
    <div className={cx(styles.faqs, mobile ? styles.faqs__mobile : "")}>
      <h3 className={styles.title} onClick={() => show()}>
        Faqs
      </h3>
      <SlidePopUp onClose={close} open={modalShown} type="bottom">
        <div className={styles.slidePopUp}>
          <FaqContent />
        </div>
      </SlidePopUp>
    </div>
  );
}

export default Faqs;
