import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./card.module.scss";

function Card({ title, text, image, imageMob, center }) {
  const smWidth = 480;
  const mdWidth = 768;
  const lgWidth = 1152;
  const isMobile = () => window.innerWidth <= smWidth;
  const isTablet = () =>
    window.innerWidth >= mdWidth && window.innerWidth <= lgWidth;

  const [imageBg, setImageBg] = useState(
    isMobile() || isTablet() ? imageMob : image
  );

  useEffect(() => {
    function handleResize() {
      setImageBg(isMobile() || isTablet() ? imageMob : image);
    }
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <div
      className={cx(styles.card, center ? styles.card__center : "")}
      style={{ backgroundImage: `url(${imageBg})` }}
    >
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.text}>{text}</div>
    </div>
  );
}

Card.defaultProps = {
  center: false,
};

Card.propTypes = {
  center: PropTypes.bool,
};

export default Card;
