import React from "react";

import ImageRetina from "../ImageRetina";
import LogoArmour from "../../assets/images/logo-under-armour.png";
import LogoArmour_2x from "../../assets/images/logo-under-armour@2x.png";
import LogoAsus from "../../assets/images/logo-asus.png";
import LogoAsus_2x from "../../assets/images/logo-asus@2x.png";
import LogoLyft from "../../assets/images/logo-lyft.png";
import LogoLyft_2x from "../../assets/images/logo-lyft@2x.png";
import LogoNorthFace from "../../assets/images/logo-north-face.png";
import LogoNorthFace_2x from "../../assets/images/logo-north-face@2x.png";
import styles from "./logosRow.module.scss";

function LogosRow() {
  return (
    <div className={styles.logos}>
      <ImageRetina
        img1x={LogoNorthFace}
        img2x={LogoNorthFace_2x}
        alt="North Face Logo"
      />
      <ImageRetina img1x={LogoArmour} img2x={LogoArmour_2x} alt="Armour Logo" />
      <ImageRetina img1x={LogoLyft} img2x={LogoLyft_2x} alt="Lyft Logo" />
      <ImageRetina img1x={LogoAsus} img2x={LogoAsus_2x} alt="Asus Logo" />
    </div>
  );
}

export default LogosRow;
