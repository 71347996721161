import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./mainApp.module.scss";

function MainApp({ title, titleImage, description, image, buttons }) {
  return (
    <div
      className={cx(styles.mainSlide)}
      style={{ backgroundImage: `url(${image})` }}
    >
      {title && (
        <div
          className={cx(styles.title, titleImage ? styles.title__image : "")}
        >
          {title}
        </div>
      )}
      {description && <div className={styles.description}>{description}</div>}
      {buttons && <div className={styles.buttons}>{buttons}</div>}
    </div>
  );
}

MainApp.defaultProps = {
  titleImage: false,
};

MainApp.propTypes = {
  titleImage: PropTypes.bool,
};

export default MainApp;
